import { defineMessages } from 'react-intl';

const messages = defineMessages({
  backToMenu: {
    id: 'ordering.cartHeader.backToMenu',
    defaultMessage: 'Back to menu',
    description: 'Indicates that this link will return to the menu.',
  },
  cartTitle: {
    id: 'ordering.cart.title',
    defaultMessage: 'Review order',
    description: 'Title for cart page',
  },
  linkPayment: {
    id: 'ordering.cart.linkPayment',
    defaultMessage: 'Link payment to pay directly',
    description: 'Link payment CTA label',
  },
  paymentIntentSheetTitle: {
    id: 'ordering.cart.paymentIntentSheetTitle',
    defaultMessage: 'Choose how to pay',
    description: 'Title for payment intent sheet',
  },
  preloadSVC: {
    id: 'ordering.cart.preloadSVC',
    defaultMessage: 'Preload and pay with a Starbucks card',
    description: 'Preload SVC CTA label',
  },
  forHereMessage: {
    id: 'ordering.cart.forHere',
    defaultMessage: 'For here',
    description: 'Button for a for-here order',
  },
  toGoMessage: {
    id: 'ordering.cart.toGo',
    defaultMessage: 'To go',
    description: 'Button for a to-go order',
  },
  reviewOrderTitle: {
    id: 'ordering.reviewOrder.title',
    defaultMessage: 'Review order ({ cartQuantity })',
    description: 'Title for authenticated user shopping cart.',
  },
  reviewOrderGuestTitle: {
    id: 'ordering.reviewOrder.guestTitle',
    defaultMessage: 'Guest checkout ({ cartQuantity })',
    description: 'Title for a guest user shopping cart.',
  },
  estimateMoreThan20: {
    id: 'ordering.estimateMoreThan20',
    defaultMessage: 'Ready in around 20+ minutes',
    description: 'Order pickup estimate label for wait time more than 20min',
  },
  estimateMoreThan20Compact: {
    id: 'ordering.estimateMoreThan20Compact',
    defaultMessage: '20+ mins',
    description:
      'Compact version of order pickup estimate label for wait time more than 20min',
  },
  estimateLessThan20: {
    id: 'ordering.estimateLessThan20',
    defaultMessage:
      'Ready in around {preOrderEstimateMin}-{preOrderEstimateMax} minutes ',
    description: 'Order pickup estimate label for wait time less than 20min',
  },
  estimateLessThan20Compact: {
    id: 'ordering.estimateLessThan20Compact',
    defaultMessage: '{preOrderEstimateMin}-{preOrderEstimateMax} mins ',
    description:
      'Compact version of order pickup estimate label for wait time less than 20min',
  },
  // cart-guest-checkout
  checkoutLabel: {
    id: 'ordering.checkoutLabel',
    defaultMessage: 'Checkout',
    description: 'Checkout label',
  },
  guestCreditDebitCardLabel: {
    id: 'ordering.payCreditCardLabel',
    defaultMessage: 'Credit / debit card',
    description: 'Label for guest credit card or debit card button',
  },
  guestCheckoutAgreement: {
    id: 'ordering.guestCheckoutAgreement',
    defaultMessage:
      'By using guest checkout, you agree to the Terms of Use, the use of the information collected for analytics, and acknowledge reading the Privacy Notice',
    description: 'Guest checkout agreement text',
  },
  guestCheckoutAcceptedCards: {
    id: 'ordering.guestCheckoutAcceptedCards',
    defaultMessage: 'Visa, Mastercard, American Express, Discover accepted.',
    description: 'A11y label for accepted credit/debit cards logo',
  },
  guestCheckoutContentHeader: {
    id: 'ordering.guestCheckoutContentHeader',
    defaultMessage: 'Credit / debit checkout',
    description:
      'Title for guest add credit or debit card payment content page',
  },
  guestCheckoutHeader: {
    id: 'ordering.guestCheckoutHeader',
    defaultMessage: 'Add a credit / debit card',
    description: 'Title for guest add credit or debit card payment header page',
  },
  guestPickupInfo: {
    id: 'ordering.guestPickupInfo',
    defaultMessage: '{pickupOption} at {storeName}',
    description:
      'Pickup option and store name label within the guest order summary',
  },
  guestOrderItemsCount: {
    id: 'ordering.guestOrderItemsCount',
    defaultMessage:
      '{itemsNumber} {itemsNumber, plural, one {item} other {items}} in your order',
    description:
      'guest order items count message within the guest order summary',
  },
  guestEmailMarketingHeader: {
    id: 'ordering.guestEmailMarketingHeader',
    defaultMessage: 'Sign up for emails from Starbucks (optional)',
    description: 'Header label for opt-in to email marketing',
  },
  guestEmailMarketingDescription: {
    id: 'ordering.guestEmailMarketingDescription',
    defaultMessage:
      'Know about products, offers, announcements and initiatives.',
    description: 'Description label for opt-in to email marketing',
  },
  timeSelectionLabel: {
    id: 'ordering.timeSelectionLabel',
    defaultMessage: 'Pickup time',
    description: 'Label for the pickup time selection',
  },
  timeSelectionNextAvailable: {
    id: 'ordering.timeSelectionNextAvailable',
    defaultMessage: 'Next Pickup:',
    description: 'Label for the next available slot',
  },
  timeSlotUnavailable: {
    id: 'ordering.timeSlotUnavailable',
    defaultMessage: ' (unavailable)',
    description: 'Label for unavailable time slot',
  },
  timeSelectionAtCapacity: {
    id: 'ordering.timeSelectionAtCapacity',
    defaultMessage: 'At capacity',
    description: 'Label for all time slots unavailable',
  },
  storeAtCapacityDialogHeading: {
    id: 'ordering.storeAtCapacityDialogHeading',
    description: 'Heading for store at capacity dialog',
    defaultMessage: 'This store is at capacity for ordering ahead',
  },
  storeAtCapacityDialogBody: {
    id: 'ordering.storeAtCapacityDialogBody',
    description: 'Body copy for store at capacity dialog',
    defaultMessage:
      'We’re giving every order the attention it deserves. Please check pickup times again in a few minutes or select a different pickup store.',
  },
  timeSlotRescheduledDialogHeading: {
    id: 'ordering.timeSlotRescheduledDialogHeading',
    description: 'Heading for time slot rescheduled dialog',
    defaultMessage: 'The next pickup time is {scheduledTimeSlot}',
  },
  timeSlotRescheduledDialogBody: {
    id: 'ordering.timeSlotRescheduledDialogBody',
    description: 'Body copy for time slot rescheduled dialog',
    defaultMessage:
      'Your pickup time is no longer available. We’ve updated it to the first available slot, please choose a different time if needed. Thanks!',
  },
  timeSelectionDropdownAriaLabel: {
    id: 'ordering.timeSelectionDropdownAriaLabel',
    defaultMessage: 'Select to reschedule',
    description: 'Aria Label for the time selection dropdown component',
  },
  initialTooltipHeading: {
    id: 'ordering.initialTooltipHeading',
    defaultMessage: 'Pick it up on your time',
    description: 'Heading message for scheduled ordering initial tooltip',
  },
  initialTooltipBody: {
    id: 'ordering.initialTooltipBody',
    defaultMessage: 'Now you can order up to one hour in advance.',
    description: 'Body message for scheduled ordering initial tooltip',
  },
  firstAvailableTimeTooltipHeading: {
    id: 'ordering.firstAvailableTimeTooltipHeading',
    defaultMessage: 'This is the first available time',
    description:
      'Heading message for scheduled ordering tooltip when next slot is no longer available',
  },
  firstAvailableTimeTooltipBody: {
    id: 'ordering.firstAvailableTimeTooltipBody',
    defaultMessage:
      "You can select a different option if there's one that works better.",
    description:
      'Body message for scheduled ordering tooltip when next slot is no longer available',
  },
  scheduledOrdersUnavailable: {
    id: 'ordering.scheduledOrdersUnavailable',
    defaultMessage: 'Scheduled orders unavailable',
    description:
      'Message for select option when there is only one available time slot',
  },
  ariaLiveScheduledOrderingTimeSlotsLoaded: {
    id: 'ordering.ariaLiveScheduledOrderingTimeSlotsLoaded',
    defaultMessage: 'Scheduled time successfully loaded',
    description: 'Message for voiceover when time slots have loaded',
  },
});

export default messages;
